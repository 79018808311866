import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 2,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: 'dashboard',
    },

    {
        id: 100,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/fleet',
    },

    {
        id: 98,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/insurance',
    },



    // {
    //     id: 2002,
    //     label:'W BB Dashboard',
    //     icon: 'bx-home-circle',
    //     link: '/adlp/dashboard/blackberry-II',
    // },

    {
        id: 54,
        label: 'Provider Dashboard',
        icon: 'bx-home-circle',
        link: 'provider'
    },

    {
        id: 198,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/Fleet',
    },

    {
        id: 99198,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/default/encova',
    },

    {
        id: 9198,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/default/geico',
    },

    {
        id: 3,
        label: 'Admin Dashboard',
        icon: 'bx-home-circle',
        link: 'admin',
    },
    // {
    //     id: 2001,
    //     label:'Blackberry IVY',
    //     icon: 'bx bx-desktop',
    //     link: '/adlp/dashboard/blackberry',
    // },

    {
        id: 2001,
        // label:'Pioneer',
        label:'Device',
        // icon: 'bx bxs-dashboard',
        icon: 'bx bx-usb',
        subItems: [
            {
                id: 88,
                label: 'VVDN',
                // link: '/adlp/dashboard/pioneer',
                // parentId: 2001
                subItems: [
                    {
                        id: 88,
                        label: 'Live Trip',
                        link: '/adlp/dashboard/livetrip',
                        parentId: 2001
                    },
                    {
                        id: 89,
                        label: 'Post Trip',
                        link: '/adlp/dashboard/posttripdetail',
                        parentId: 2001
                    },
                ]
            },
            {
                id: 89,
                label: 'Voyo',
                // link: '/adlp/dashboard/posttripdetail',
                // parentId: 2001
                subItems: [
                    {
                        id: 88,
                        label: 'Live Trip',
                        link: '/adlp/dashboard/default/voyo',
                        parentId: 2001
                    },
                    {
                        id: 89,
                        label: 'Post Trip',
                        link: '/adlp/dashboard/default/voyo/posttrip-details',
                        parentId: 2001
                    },
                ]
            },
            {
                id: 89,
                label: 'Geometris',
                // link: '/adlp/dashboard/posttripdetail',
                // parentId: 2001
                subItems: [
                    {
                        id: 88,
                        label: 'Live Trip',
                        link: '/adlp/dashboard/pioneer',
                        parentId: 2001
                    },
                    {
                        id: 89,
                        label: 'Post Trip',
                        link: '/adlp/dashboard/posttripdetail',
                        parentId: 2001
                    },
                ]
            },
            {
                id: 89,
                label: 'Teletronics',
                // link: '/adlp/dashboard/posttripdetail',
                // parentId: 2001
                subItems: [
                    {
                        id: 88,
                        label: 'Live Trip',
                        link: '/adlp/dashboard/pioneer',
                        parentId: 2001
                    },
                    {
                        id: 89,
                        label: 'Post Trip',
                        link: '/adlp/dashboard/posttripdetail',
                        parentId: 2001
                    },
                ]
            },
            
        ]

        // subItems: [
        //     {
        //         id: 88,
        //         label: 'Live Trip',
        //         link: '/adlp/dashboard/pioneer',
        //         parentId: 2001
        //     },
        //     {
        //         id: 89,
        //         label: 'Post Trip',
        //         link: '/adlp/dashboard/posttripdetail',
        //         parentId: 2001
        //     },
        // ]

    },
    // {
    //     id: 20010,
    //     label:'BlackBerry CES',
    //     icon: 'bx bx-desktop',
    //     link: '/adlp/dashboard/blackBerry',
    // },

    // {
    //     id: 20012,
    //     label:'Live Trip',
    //     icon: 'bx bx-car',
    //     link: '/adlp/dashboard/livetrip'
    // },

   
    // {
    //     id: 8901,
    //     label: 'Pioneer CES',
    //     icon: 'bx bx-car',
    //     link: '/adlp/developer-corner/trip-simulator',
    // },
    // {
    //     id: 1010,
    //     label:'Post Trip',
    //     icon: 'bx bxs-dashboard',
    //     link: '/adlp/dashboard/posttripdetail'
    // },

 

    {
        id: 290,
        label: 'Sales Dashboard',
        icon: 'bx-home-circle',
        link: '/adlp/dashboard/salesDashboard',
    },

    {
        id: 12,
        label: 'Vehicle',
        icon: 'bx-car',
        subItems: [
            {
                id: 14,
                label: 'Manage Vehicle',
                link: 'vehicles/managevehicle',
                parentId: 12
            },
            {
                id: 15,
                label: 'Vehicle Status',
                link: 'vehicles/vehicle-status',
                parentId: 12
            },
            // {
            //     id: 110,
            //     label: 'Track Vehicle',
            //     link: 'vehicles/track-vehicle',
            //     parentId: 12
            // },
        ]
    },

    // {
    //     id: 290,
    //     label: 'Crash',
    //     icon: 'bx-home-circle',
    //     link: '/adlp/crash-storyline/:id',
    // },

    {
        id: 199,
        label: 'Manage',
        icon: 'bx-car',
        subItems: [
            {
                id: 1991,
                label: 'Manage Fleet',
                link: 'vehicles/manage-fleet',
                parentId: 199
            },
            {
                id: 1992,
                label: 'Manage Vehicle',
                link: 'vehicles/managevehicle',
                parentId: 199
            },
        ]
    },

    

    {
        id: 29,
        label: 'Insights',
        icon: 'bx-bar-chart-alt',
        link: 'insight/insight',
    },

    {
        id: 39,
        label: 'Product Packages',
        icon: 'bx bx-dollar-circle',
        link: 'pricing'
    },

    {
        id: 48,
        label: 'Manage Vehicles',
        icon: 'bx-car',
        link: 'manage-vehicles',

    },

    {
        id: 45,
        label: 'Manage Users',
        icon: 'bx bx-user',
        subItems: [
            {
                id: 46,
                label: 'Consumer',
                link: 'manage-user/consumer',
            },
            {
                id: 47,
                label: 'Provider',
                link: 'manage-user/provider',
            },
            {
                id: 960,
                label: 'Users',
                link: 'manage-user/user',
            }
        ]
    },

    {
        id: 48,
        label: 'Data Pack Manager',
        icon: 'mdi mdi-database',
        subItems: [
            {
                id: 49,
                label: 'Provider',
                link: 'data-pack-manager/oem-packs',
            },
            {
                id: 50,
                label: 'Consumer',
                link: 'data-pack-manager/consumer-packs',
            },
            {
                id: 51,
                label: 'Category',
                link: 'data-pack-manager/category-packs'
            }
        ]
    },
  
    {
        id: 36,
        label: 'CX Marketplace',
        icon: 'bx-store',
        link: 'marketplace',
    },

    {
        id: 1001,
        label: 'Manage Fleet',
        icon: 'bx-car',
        link: '/adlp/vehicles/manage-fleet',
    },

    {
        id: 1001,
        label: 'TCO Toolkit',
        icon: 'bx-dollar-circle',
        link: '/adlp/dashboard/tcosearch',
    },

    {
        id: 53,
        label: 'Billing Manager',
        icon: 'bx-receipt',
        link: 'billing-manager',
    },

    {
        id: 2432,
        label: 'Audit',
        icon: 'bx-list-check',
        subItems: [
            {
                id: 24321,
                label: 'VIN History',
                link: 'audit/audit',
            },
            {
                id: 24322,
                label: 'User History',
                link: 'audit/user-history',
            },
        ]
    },

    {
        id: 54,
        label: 'Insights',
        icon: 'bx-bar-chart-alt',
        subItems: [
            {
                id: 49,
                label: 'Car Application Usage',
                link: 'insight/car-application-usage'
            },
            {
                id: 50,
                label: 'Car Service Usage',
                link: 'insight/car-service-usage',
            },
            {
                id: 51,
                label: 'Car Softkey Usage',
                link: 'insight/car-softkey-usage'

            },
            {
                id: 52,
                label: 'Sales Insights',
                link: 'insight/sales-insight'

            }
        ]

    },

    {
        id: 54,
        label: 'OEM Application Center',
        icon: 'bx-receipt',
        subItems: [
            {
                id: 53,
                label: 'VAS Management',
                link: 'insight/vas-management'
            },
            {
                id: 54,
                label: 'Connected Car',
                link: 'insight/connected-car',
            },
            // {
            //     id: 540,
            //     label: 'Live Trip Analysis',
            //     link: 'insight/trip-analysis',
            // },
        ]
    },

    {
        id: 69,
        label: 'Billing',
        icon: 'bx-receipt',
        link: 'billing'
    },
    {
        id: 601,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: 'dashboard/insurance-dashbaord'
    },

    {
        id: 602,
        label: 'Vehicle Profile',
        icon: 'bx-receipt',
        link: 'insurance/vehicle-profile'
    },

    {
        id: 603,
        label: 'Configuration',
        icon: 'bx-cog',
        link: 'insurance/configuration'
    },

];

