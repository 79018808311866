<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a href="index.html" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/small_logo.png" alt="" height="45" class="small-logo">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo_new.png" alt="" height="45" class="main-logo">
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
      <ng-container *ngIf="user =='role_provider'">
        <h4 style="font-size: 16px; font-weight:600; color:#f37721; text-align:center; padding-top:25px;"><i
            class="fa fa-car" style="font-size: 16px; font-weight:600;"></i>&nbsp; Provider View</h4>
      </ng-container>
      <ng-container
        *ngIf="user =='role_consumer' && (customConsumer === 'Azuga' ||  customConsumer === 'IMT Insurance' || customConsumer === 'Rockingham')">
        <h4 style="font-size: 16px; font-weight:600; color:#f37721; text-align:center; padding-top:25px;"><i
            class="fa fa-industry" style="font-size: 16px; font-weight:600;"></i>&nbsp; Consumer View</h4>
      </ng-container>
    </div>
    <!-- For Admin Role -->
    <ng-container *ngIf="user =='admin'">
      <div class="d-flex">
        <ng-container>
          <div class="dropdown d-inline-block" ngbDropdown>
            <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
              <span class="d-none d-xl-inline-block ms-1 search">Developer's Corner</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block search"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0)" (click)="datapihub()">
                Data API Hub</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript: void(0);" (click)="cxtaxonomy()">CerebrumX Taxonomy</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item d-block" href="javascript: void(0);" (click)="tripsimulator()">
                Trip Simulator</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item d-block" href="javascript: void(0);" (click)="vindecoder()">
                VIN Decoder</a>
            </div>
          </div>
        </ng-container>
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1">Welcome ! {{loginUser?.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <ng-container>
              <a class="dropdown-item" (click)="openModal(content)"><i
                  class="bx bx-user font-size-16 align-middle me-1"></i>
                Change Profile</a>
              <div class="dropdown-divider"></div>
            </ng-container>
            <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-wallet font-size-16 align-middle me-1"></i>Privacy Status</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item d-block" href="javascript: void(0);"  (click)="support()"><i
                class="bx bx-wrench font-size-16 align-middle me-1"></i>
              Support</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- For Consumer Role Fleet -->
    <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Fleet')">
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1" style="text-transform: capitalize; "> Welcome !
              {{loginUser?.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item d-block" href="javascript: void(0)" (click)="support()"><i
                class="bx bx-wrench font-size-16 align-middle me-1"></i>
              Support</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- For Consumer Role All -->
    <ng-container
      *ngIf="user =='role_consumer' && (customConsumer === 'Blackberry'  || customConsumer === 'Blackberryces'  || customConsumer === 'Pioneer-Usa' || customConsumer === 'Pioneer' || customConsumer === 'Tmobile'  || customConsumer === 'AssetWorks' || customConsumer === 'OneStepGps' || customConsumer === 'Hdfleet')">
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <!-- <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1 search">Developer's Corner</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block search"></i>
          </button> -->
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0)" (click)="datapihub()">
              Data API Hub</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);" (click)="cxtaxonomy()">CerebrumX Taxonomy</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item d-block" href="javascript: void(0);" (click)="vindecoder()">
              VIN Decoder</a>
          </div>
        </div>
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1" style="text-transform: capitalize; ">Welcome !
              {{loginUser?.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <ng-container
              *ngIf="user == 'admin' || user =='role_consumer' && (customConsumer === 'Azuga' || customConsumer === 'IMT Insurance')">
              <a class="dropdown-item" (click)="openModal(content)"><i
                  class="bx bx-user font-size-16 align-middle me-1"></i>
                Change Profile</a>
              <div class="dropdown-divider"></div>
            </ng-container>
         
            <a class="dropdown-item d-block" href="javascript: void(0);" (click)="support()"><i
                class="bx bx-wrench font-size-16 align-middle me-1"></i>
              Support</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container
    *ngIf="user =='role_consumer' && (customConsumer === 'Azuga' ||  customConsumer === 'IMT Insurance' || customConsumer === 'Rockingham' || customConsumer === 'Insurance')">
    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <span class="d-none d-xl-inline-block ms-1 search">Developer's Corner</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block search"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)" (click)="datapihub()">
            Data API Hub</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="cxtaxonomy()">CerebrumX Taxonomy</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="vindecoder()">
            VIN Decoder</a>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <span class="d-none d-xl-inline-block ms-1" style="text-transform: capitalize; ">Welcome !
            {{loginUser?.name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <ng-container
            *ngIf="user == 'admin' || user =='role_consumer' && (customConsumer === 'Azuga' || customConsumer === 'IMT Insurance')">
            <a class="dropdown-item" (click)="openModal(content)"><i
                class="bx bx-user font-size-16 align-middle me-1"></i>
              Change Profile</a>
            <div class="dropdown-divider"></div>
          </ng-container>
       
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="support()"><i
              class="bx bx-wrench font-size-16 align-middle me-1"></i>
            Support</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'Logout' | translate}}</a>
        </div>
      </div>
    </div>
  </ng-container>
    <!-- For Provider Role -->
    <ng-container *ngIf="user =='role_provider'">
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1" style="text-transform:capitalize">Welcome !
              {{loginUser?.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item" (click)="openModal(content)"><i
                class="bx bx-user font-size-16 align-middle me-1"></i>
              Change Profile</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-wallet font-size-16 align-middle me-1"></i>Privacy Status</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item d-block" href="javascript: void(0);"  (click)="support()"><i
                class="bx bx-wrench font-size-16 align-middle me-1"></i>
              Support</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- For LG Role -->
    <ng-container *ngIf="user =='role_lg'">
      <div class="d-flex">
        <div class="dropdown d-inline-block" ngbDropdown>
          <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
            <span class="d-none d-xl-inline-block ms-1" style="text-transform:capitalize">Welcome !
              {{loginUser?.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item d-block" href="javascript: void(0);"  (click)="support()"><i
                class="bx bx-wrench font-size-16 align-middle me-1"></i>
              Support</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</header>

<ng-template #content role="document" let-modal style="margin-top:40%">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Role</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 col-lg-12">
        <select class="form-select" (change)="onChange($event)">
          <option value="" disabled selected hidden> Change Role</option>
          <option value="admin">Admin</option>
          <option value="opentype">Consumer</option>
          <option value="provider">Provider</option>
        </select>
      </div>
    </div>

    <div class="row" style="margin-top:20px;" *ngIf="changeRole">
      <div class="col-xs-12 col-lg-12">
        <select class="form-select" (change)="selectDashboard($event.target.value)">
          <ng-container *ngIf="this.selectedType == 'provider'">
            <option value="" disabled selected hidden>Select Provider</option>
          </ng-container>
          <ng-container *ngIf="this.selectedType == 'opentype'">
            <option value="" disabled selected hidden>Select Consumer</option>
          </ng-container>

          <option *ngFor="let consumer of selectUserData" value="{{consumer?.value}}">{{consumer?.name}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="submit(); modal.close('Close click')">Submit</button>
  </div>
</ng-template>