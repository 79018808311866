import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { AuthenticationService,  } from '../services/auth.service';
// import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    
	constructor(private router: Router, private userAuthService: AuthenticationService,
        // private toastr : ToastrService
        ) { }
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userAuthService.getActivateUser()) {
            // logged in so return true
            return true;
        }
        else{
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        // this.toastr.success("You must first log in !");
        return false;
      }
    }
}
@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

	constructor(private router: Router, private userAuthService: AuthenticationService
        // ,private toastr : ToastrService
        ) { }
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userAuthService.getsessionStorageAdmin()) {
            // logged in so return true
            return true;
        }
        else{
          console.log('abc')
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        // this.toastr.success("You must first log in !");
        return false;
      }
    }
}

@Injectable({
  providedIn: 'root'
})
export class ProviderAuthGuard implements CanActivate {

	constructor(private router: Router, private userAuthService: AuthenticationService,
        // private toastr : ToastrService
        ) { }
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
        if (this.userAuthService.getsessionStorageProvider()) {
            // logged in so return true
            return true;
        }
        else{ 
        console.log('xyz')
          this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        // this.toastr.success("You must first log in !");
        return false;}
  
        // not logged in so redirect to login page with the return url
       
    }
}

