<ng-template #contentTemplate>
  <div id="sidebar-menu">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- For Role Consumer Azuga -->
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Azuga')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Onboarding' && item?.label != 'Pioneer CES'  &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '99198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard'  && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard'  && (item?.label != 'Dashboard' || item?.id != '198') && (item?.label != 'Dashboard' || item?.id != '9198')  && item?.label != 'Manage' && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicle Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Consumer IMT Insurance -->
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'IMT Insurance')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Onboarding' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') &&  item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198') && (item?.label != 'Track Vehicle' || item?.id != '110' || item?.parentId != '12') && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Geico')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Onboarding' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '2')  &&  item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198') && (item?.label != 'Track Vehicle' || item?.id != '110' || item?.parentId != '12') && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Encova')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Onboarding' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '9198') && (item?.label != 'Dashboard' || item?.id != '2') &&  item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198') && (item?.label != 'Track Vehicle' || item?.id != '110' || item?.parentId != '12') && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Consumer Blackberry -->
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Blackberry')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer'  && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Blackberryces')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && item?.label != 'Device' && item?.label != 'Live Trip' && item?.label != 'Blackberry IVY' && item?.label != 'Post Trip' && item?.label != 'Pioneer'  && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198')  && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Pioneer')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Device' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES'  && item?.label != 'Post Trip' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Tmobile')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' &&  item?.label != 'Live Trip' && item?.label != 'Device' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Pioneer' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Pioneer-Usa')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' &&  item?.label != 'Live Trip' && item?.label != 'Device' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Pioneer' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'AssetWorks')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && item?.label != 'Device' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Pioneer' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'OneStepGps')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && item?.label != 'Device' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Pioneer' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Hdfleet')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && item?.label != 'Device' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Pioneer' && item?.label != 'Blackberry IVY' && item?.label != 'Vehicle' && item?.label != 'CX Marketplace' && item?.label != 'Insights' && item?.label != 'Cx Ma' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Consumer Inrix -->
      <ng-container *ngIf="user =='role_consumer' && (customConsumer === 'Inrix')">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Dashboard' || item?.id != '198')  && item?.label != 'Manage'  && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '100')  && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicles Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Consumer Fleet -->
      <ng-container *ngIf="user =='role_consumer' && customConsumer === 'Fleet'">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Onboarding' && item?.label != 'Pioneer CES' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Pioneer' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit'  && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '100') && item?.label != 'CX Marketplace' && item?.label != 'Vehicle' && item?.label != 'Insights' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601')  ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicle Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Admin -->
      <ng-container *ngIf="user =='admin'">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item?.label != 'Billing' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'Post Trip' && item?.label != 'W BB Dashboard' && item?.label != 'Billing Manager' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && item?.label != 'Manage' &&  item?.label != 'Provider Dashboard' && item?.label != 'Insight'  && item?.label != 'Insights' && item?.label != 'Vehicle' && item?.label != 'Product Packages' && item?.label != 'Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') 
            && (item?.label != 'Crash Storyline' || item?.id != '604')">

            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                    [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                    {{ subitem.label | translate}}
                  </a>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Provider -->
      <ng-container *ngIf="user =='role_provider'">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" item?.label != 'Sales Dashboard' && item?.label != 'Pioneer CES' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'W BB Dashboard' && item?.label != 'Manage Fleet' && item?.label != 'Blackberry IVY' && item?.label != 'Pioneer' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.subItems?.label != 'Vehicle Status' && item?.label != 'Billing' && item?.label != 'Manage' && (item?.label != 'Insights' || item?.id != '29') && item?.label != 'Product Packages' && item?.label != 'Dashboard'
    && item?.label != 'Admin Dashboard' && item?.subItems?.label != 'Live Trip Analysis' && item?.label != 'CX Marketplace'  && item?.label != 'Device'  && item?.label != 'Vehicle' && item?.label != 'Manage Users'&& item?.label != 'Data Pack Manager'&& item?.label != 'Manage Vehicles'&& item?.label != 'Billing Manager'
    && item?.label != 'Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <ng-container *ngIf="customConsumer == 'stellantis' || customConsumer == 'ford' ; else otherr">
                <ng-container *ngIf="item.label != 'OEM Application Center'">
                  <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                    [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                    <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                    <span> {{ item.label | translate }}</span>
                    <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                      *ngIf="item.badge">{{item.badge.text |
                      translate}}</span>
                  </a>

                  <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref"
                    routerLinkActive="active">
                    <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                    <span> {{ item.label | translate }}</span>
                    <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                      *ngIf="item.badge">{{item.badge.text |
                      translate}}</span>
                  </a>

                  <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                    <li *ngFor="let subitem of item.subItems">
                      <ng-container *ngIf="subitem.id != 15 && subitem.id !=540">
                        <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                          [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                          {{ subitem.label | translate}}
                        </a>
                      </ng-container>
                      <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                        [attr.data-parent]="subitem.parentId">
                        {{ subitem.label | translate}}
                      </a>
                      <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                        <li *ngFor="let subSubitem of subitem.subItems">
                          <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                            routerLinkActive="active" class="side-nav-link-ref">
                            {{ subSubitem.label | translate }}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ng-container>

              </ng-container>
              <ng-template #otherr>
                <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                  [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                  <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                  <span> {{ item.label | translate }}</span>
                  <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                    *ngIf="item.badge">{{item.badge.text |
                    translate}}</span>
                </a>

                <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                  <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                  <span> {{ item.label | translate }}</span>
                  <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                    *ngIf="item.badge">{{item.badge.text |
                    translate}}</span>
                </a>

                <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                  <li *ngFor="let subitem of item.subItems">
                    <ng-container *ngIf="subitem.id != 15 && subitem.id !=540">
                      <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                        [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                        {{ subitem.label | translate}}
                      </a>
                    </ng-container>
                    <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                      [attr.data-parent]="subitem.parentId">
                      {{ subitem.label | translate}}
                    </a>
                    <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                      <li *ngFor="let subSubitem of subitem.subItems">
                        <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                          routerLinkActive="active" class="side-nav-link-ref">
                          {{ subSubitem.label | translate }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ng-template>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role LG -->
      <ng-container *ngIf="user =='role_lg'">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf="item?.label != 'Sales Dashboard' && item?.label != 'Pioneer CES' && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'Pioneer' && item?.label != 'W BB Dashboard' && item?.label != 'Blackberry IVY' && item?.label != 'Audit' && item?.label != 'Manage Fleet' && item?.label != 'TCO Toolkit' && item?.label != 'Manage' && (item?.label != 'Insights' || item?.id != '29') && item?.label != 'Vehicle' && item?.label != 'Product Packages' && item?.label != 'Dashboard'
    && item?.label != 'Provider Dashboard' && item?.label != 'CX Marketplace' && item?.label != 'Manage Users'&& item?.label != 'Data Pack Manager'&& item?.label != 'Manage Vehicles'&& item?.label != 'Billing Manager'
    && item?.label != 'Dashboard' && item?.label != 'Vehicle Profile' && item?.label != 'Configuration' && item?.label != 'Consumer Profile' && item?.label != 'insights' && (item?.label != 'Dashboard' || item?.id != '601') ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                    [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                    {{ subitem.label | translate}}
                  </a>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- For Role Consumer Insurance -->
      <ng-container *ngIf="user =='role_consumer' && customConsumer === 'Insurance' ">
        <ng-container *ngFor="let item of menuItems">
          <ng-container
            *ngIf=" (item?.label != 'Dashboard' || item?.id != '198') && (item?.label != 'Dashboard' || item?.id != '99198') && (item?.label != 'Dashboard' || item?.id != '9198') && item?.label != 'BlackBerry CES' && item?.label != 'Post Trip' && item?.label != 'W BB Dashboard'  && item?.label != 'Manage Fleet' && item?.label != 'Blackberry IVY' && item?.label != 'Pioneer' && item?.label != 'Audit' && item?.label != 'Manage Fleet' &&  item?.label != 'Configuration' && item?.label != 'TCO Toolkit' && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Onboarding'  && item?.label != 'Billing' && item?.label != 'Live Trip Analysis' && item?.label != 'Sales Dashboard' && (item?.label != 'Insights' || item?.id != '54') && (item?.label != 'Dashboard' || item?.id != '100') && item?.label != 'CX Marketplace' && item?.label != 'Vehicle' && item?.label != 'Insights' && item?.label != 'Product Packages' && (item?.label != 'Dashboard' || item?.id != '98') && (item?.label != 'Dashboard' || item?.id != '2') && item?.label != 'Provider Dashboard' && item?.label != 'OEM Application Center' && item?.label != 'Data Pack Manager' && item?.label != 'Billing Manager'  && item?.label != 'Package Manager' && item?.label != 'Category Manager' && item?.label != 'Manage Users' && item?.label != 'Data Packs' && item?.label != 'Admin Dashboard' && item?.label != 'Manage Vehicles' && item?.label != 'Sales Dashboard' && item?.label != 'Manage'  ">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>
                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end"
                  *ngIf="item.badge">{{item.badge.text |
                  translate}}</span>
              </a>

              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <ng-container *ngIf="subitem.label != 'Vehicle Summary'">
                    <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                      [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                      {{ subitem.label | translate}}
                    </a>
                  </ng-container>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        routerLinkActive="active" class="side-nav-link-ref">
                        {{ subSubitem.label | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>

      </ng-container>
    </ul>
  </div>
</ng-template>
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>