import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import jwt_decode from "jwt-decode";
import { AuthenticationService } from '../../core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersRoleService } from '../user-role/users-role.service';
import { LoginapiService } from '../../auth/loginapi.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

export class TopbarComponent implements OnInit {
  selectedType: any;
  loginUserDetail: any
  loginUser: any;
  user: any;
  multiRoles: any;
  userRoleResponse: any;
  loginResponse: any;
  dataLoginName: any;
  selectUserData:any =[]
  consumerData:any = {
  opentype:[{name:'A***a',value:'Azuga'},{name:'R*******m',value:'IMT Insurance'},{name:'Bl*******y',value:'Blackberry'},{name:'Pi****r',value:'Pioneer'},{name:'A***m',value:'Axiom'},{name:'C*******h',value:'Clearpath'},{name:'I***x',value:'Inrix'}],
  provider:[{name:'S******s',value:'stellantis'},{name:'F**d',value:'ford'},{name:'T*****a',value:'toyota'},{name:'N*****n',value:'nissan'},{name:'V********e',value:'voyomotive'}],
}
  userDash: any;
  changeRole: boolean;
  customConsumer: any;
  constructor(@Inject(DOCUMENT) private document: any, private loginapiservice: LoginapiService, 
  private modalService: NgbModal, private _userRole: UsersRoleService, private router: Router, private authService: AuthenticationService) { }
  openMobileMenu: boolean;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.loginUserDetail = sessionStorage.getItem('user-token');
    this.showRole();
    this.decodeToken()
  }

  showRole() {
    let userRolLogin = JSON.stringify(sessionStorage.getItem('userRole'));
    this.user = JSON.parse(userRolLogin);
    this.multiRoles = JSON.parse(sessionStorage.getItem('multiRole'));
    let customConsumers =JSON.stringify(sessionStorage.getItem('custom-consumer'));
    this.customConsumer=JSON.parse(customConsumers); 
  }

  openModal(content: any) {
    this.modalService.open(content);
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    let userdata = JSON.parse(this.loginUserDetail);
    let token = userdata.accessToken;
    const logoutToken: any = {
      accessToken: token
    }
    this.authService.logOut(logoutToken);
  }
  decodeToken() {
    var userdetail = JSON.parse(this.loginUserDetail)
    if (userdetail)
      var token = userdetail.idToken;
    this.loginUser = jwt_decode(token);
  }

  datapihub() {
    this.router.navigate(['adlp/developer-corner/dataapihub']);
  }

  cxtaxonomy() {
    this.router.navigate(['adlp/developer-corner/cerebrumx-taxonomy'])
  }

  createcustommodel() {
    this.router.navigate(['adlp/developer-corner/custom-model'])
  }

  vindecoder(){
    this.router.navigate(['adlp/developer-corner/generatereports'])
  }

  tripsimulator(){
    this.router.navigate(['adlp/developer-corner/trip-simulator'])
  }

  configuration(){
    this.router.navigate(['adlp/insurance/configuration'])
  }

  onChange(event) {
    this.selectedType = event.target.value;
    if(this.selectedType == 'provider') {
      this.changeRole = true;
      this.selectUserData = this.consumerData.provider
    } else if(this.selectedType == 'admin') {
      this.changeRole = false;
      this.selectUserData = this.consumerData.admin
    } else {
      this.changeRole = true;
      this.selectUserData = this.consumerData.opentype
    }
  }

  support(){
    this.router.navigate(['adlp/dashboard/support'])
  }

  selectDashboard(evt) {
    this.userDash = evt;
  }
  
  submit() {
    if(this.userDash == 'IMT Insurance') {
      sessionStorage.setItem('custom-consumer', 'IMT Insurance')
      sessionStorage.setItem('custom-changeConsumer','IMT Insurance')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.router.navigate(['adlp'])
      this.authService.sendDataToChildComponent('IMT Insurance')
    }
    else if (this.userDash == 'Azuga') {
      sessionStorage.setItem('custom-consumer', 'Azuga')
      sessionStorage.setItem('custom-changeConsumer','Azuga')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Azuga')
      this.router.navigate(['adlp/dashboard/fleet'])
    }
    else if (this.userDash == 'Axiom') {
      sessionStorage.setItem('custom-consumer', 'Azuga')
      sessionStorage.setItem('custom-changeConsumer','Axiom')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Azuga')
      this.router.navigate(['adlp/dashboard/default/consumer-axiom'])
    }

    else if (this.userDash == 'Inrix') {
      sessionStorage.setItem('custom-consumer', 'Azuga')
      sessionStorage.setItem('custom-changeConsumer','Inrix')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Azuga')
      this.router.navigate(['adlp/dashboard/default/consumer-inrix'])
    }

    else if (this.userDash == 'Clearpath') {
      sessionStorage.setItem('custom-consumer', 'Azuga')
      sessionStorage.setItem('custom-changeConsumer','Clearpath')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Azuga')
      this.router.navigate(['adlp/dashboard/default/consumer-clearpath'])
    }

    else if (this.userDash == 'Geico') {
      sessionStorage.setItem('custom-consumer', 'IMT Insurance')
      sessionStorage.setItem('custom-changeConsumer','Geico')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('IMT Insurance')
      this.router.navigate(['adlp/dashboard/default/geico'])
    }

    else if (this.userDash == 'Blackberry') {
      sessionStorage.setItem('custom-consumer', 'Blackberry')
      sessionStorage.setItem('custom-changeConsumer','Blackberry')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Blackberry')
      this.router.navigate(['adlp/dashboard/blackberry'])
    }

    else if (this.userDash == 'Pioneer') {
      sessionStorage.setItem('custom-consumer', 'Pioneer')
      sessionStorage.setItem('custom-changeConsumer','Pioneer')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('Pioneer')
      this.router.navigate(['adlp/dashboard/blackberry'])
    }

    else if (this.userDash == 'Encova') {
      sessionStorage.setItem('custom-consumer', 'IMT Insurance')
      sessionStorage.setItem('custom-changeConsumer','Encova')
      sessionStorage.setItem('userRole', 'role_consumer')
      this.authService.sendDataToChildComponent('IMT Insurance')
      this.router.navigate(['adlp/dashboard/default/encova'])
    }
    else if (this.userDash == 'stellantis') {
      console.log('jk');
      sessionStorage.setItem('custom-consumer', 'stellantis')
      sessionStorage.setItem('userRole', 'role_provider')
      this.router.navigate(['adlp/provider'])
      this.authService.sendDataToChildComponent('provider')
    }

    else if (this.userDash == 'nissan') {
      sessionStorage.setItem('custom-consumer', 'nissan')
      sessionStorage.setItem('userRole', 'role_provider')
      this.router.navigate(['adlp/dashboard/provider/provider-nissan'])
      this.authService.sendDataToChildComponent('provider')
    }

    else if (this.userDash == 'ford') {
      sessionStorage.setItem('custom-consumer', 'ford')
      sessionStorage.setItem('userRole', 'role_provider')
      this.router.navigate(['adlp/dashboard/provider/provider-ford'])
      this.authService.sendDataToChildComponent('provider')
    }

    else if (this.userDash == 'toyota') {
      sessionStorage.setItem('custom-consumer', 'toyota')
      sessionStorage.setItem('userRole', 'role_provider')
      this.router.navigate(['adlp/dashboard/provider/provider-toyota'])
      this.authService.sendDataToChildComponent('provider')
    }

    else if (this.userDash == 'voyomotive') {
      sessionStorage.setItem('custom-consumer', 'voyomotive')
      sessionStorage.setItem('userRole', 'role_provider')
      this.router.navigate(['adlp/dashboard/provider/provider-voyomotive'])
      this.authService.sendDataToChildComponent('provider')
    }

    else if (this.selectedType == 'admin') {
      sessionStorage.setItem('userRole', 'admin')
      this.router.navigate(['adlp/admin'])
      this.authService.sendDataToChildComponent('admin')
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }
}
