import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })

export class AuthenticationService implements OnDestroy {
    private _authLoginUrl:any = environment.url.BASE_URL 
    private _trialVersionUrl:any = `${environment.url.BASE_URL}contact-us/save`; 
     userData   : any;
     isLoggedIn = false;
     adminData  : any;
     providerData:any;
     consumerData:any;
     subjectName = new Subject<any>();
     token:any;
     constructor( 
                 private http   : HttpClient,
                 private router : Router,
               //   private toastr : ToastrService
                 ) {
                  window.addEventListener('storage', this.handleStorageEvent, false);

    this.requestSyncSessionStorage();
                  }
  
     loginUser(value) {
      return this.http.post(this._authLoginUrl+ 'account/login' ,value); 
     }
     getActivateUser() {
      return (sessionStorage.getItem("user-token"))? true : false
    }
     logOut(value) {
      return this.http.post(this._authLoginUrl + 'account/signout',value).subscribe((res)=>{
           sessionStorage.clear();
           localStorage.clear()
           window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString())
           window.localStorage.removeItem('CREDENTIALS_FLUSH')
           
         //   this.toastr.success("Successfully logged out!");
           this.router.navigate(['/']);
      },err=>{
         // this.toastr.success("Successfully logged out!");
         sessionStorage.clear();
         localStorage.clear()
         window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString())
         window.localStorage.removeItem('CREDENTIALS_FLUSH')
         this.router.navigate(['/']);
        //  sessionStorage.clear();
       }); 
   }   
  
   trialVersion(data) {
    return this.http.post(this._authLoginUrl + 'trial-account-request' ,data);  
   }
  
   getCredential() {
    return this.http.get(this._authLoginUrl + 'app-client-credentials')
  }
  getsessionStorageUser() {
    this.userData = JSON.parse(sessionStorage.getItem("user-token"));
    if (this.userData !== null) {
      var token = this.userData.idToken;
      var User = jwt_decode(token);
      var userLength: any = User["cognito:groups"].length;
      for (let i = 0; i < userLength; i++) {
        if (User["cognito:groups"][i].includes("_CONSUMER")) {
          return true;
        }
        else if (User["cognito:groups"][0].includes("_PROVIDER")) {
          return true;

        }
        else if (User["cognito:groups"][0].includes("_ADMIN")) {
          return true;

        }
        else if (User["cognito:groups"][0].includes("_LG")) {
          return true;

        }
      }

    }

  }
  getsessionStorageAdmin() {
    this.adminData = JSON.parse(sessionStorage.getItem('admin_token'));
    if (this, this.adminData !== null) {
      var token = this.adminData.idToken;
      var loginUser = jwt_decode(token);
      var userLength: any = loginUser["cognito:groups"].length;
      for (let i = 0; i < userLength; i++) {
        if (loginUser["cognito:groups"][i].includes("_ADMIN")) {
          return true;
        }
      }
    }

  }
  getsessionStorageProvider() {
    this.providerData = JSON.parse(sessionStorage.getItem('user-token'));
    if (this, this.providerData !== null) {
      var token = this.providerData.idToken;
      var loginUser: any = jwt_decode(token);
      sessionStorage.setItem('emailid', JSON.stringify(loginUser.email))
      var userLength: any = loginUser["cognito:groups"].length;
      for (let i = 0; i < userLength; i++) {
        if (loginUser["cognito:groups"][i].includes("_PROVIDER")) {
          return true;
        }
      }

    }

  }



  sendDataToChildComponent(data: any) {

    this.subjectName.next(data);

  }

  registerAccount() {
    this.router.navigate(['adlp/auth/signup'])
  }
  handleStorageEvent = (event: StorageEvent): void => {
      
    if (event.key === 'requestSyncSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'sessionStorage') {
      const sessionStorage = JSON.parse(event.newValue || '{}');
      for (const key in sessionStorage) {
        window.sessionStorage.setItem(key, sessionStorage[key]);
      }
    }
    else if(event.key === 'CREDENTIALS_FLUSH'){
        window.sessionStorage.clear()
      
    }
  };

  requestSyncSessionStorage(): void {
    if (!sessionStorage.length) {
      const current = new Date().toLocaleTimeString();
      localStorage.setItem(
        'requestSyncSessionStorage',
        'request session storage' + current
      );
    }
  }
  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent, false);
  }
   
   } 


 



