import { Injectable } from '@angular/core';

import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class IntercepterService {

  constructor(private http: HttpClient, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = JSON.parse(sessionStorage.getItem('access-token'))
    let url :any = req.url.split('?')[0]
    if (token && url != 'https://maps.googleapis.com/maps/api/geocode/json') {
      return next.handle(req.clone({ headers: req.headers.append('Authorization', 'Bearer ' + token) }))
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && ~(event.status / 100) > 3)
            return event;
          else return event
        }),
        catchError((err: any, caught) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/auth/login']);
            }
            return throwError(err);
          }
        }) 
      );
    }
    else {
      return next.handle(req);
    }
  }
}
