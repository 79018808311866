import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as jwt_decode from "jwt-decode";
import { environment } from '../../../environments/environment'
import { map,catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersRoleService {
  userRoleResourse: any = 'https://api-staging.cerebrumx.ai/v1/dash-view/role';
  userRole:any; 

  baseUrl:any="https://api-staging.cerebrumx.ai/v1/"

  constructor(private http: HttpClient) {
      
   
   }

  getUserRole(){
    this.userRole=sessionStorage.getItem('userlogin')
    console.log(this.userRole)
    let params = new HttpParams().set("role",this.userRole); 
    return this.http.post(this.userRoleResourse , params);

  }



  

}
