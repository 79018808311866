export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  //  url: {
  //    BASE_URL: 'https://beta.cerebrumx.ai/bk/v1/',
  //    DS_URL: 'https://beta.cerebrumx.ai/ds/v1/',
  //    DS_URL1: 'https://beta.cerebrumx.ai/ds/v1/',
  //    DS_URLS: 'https://beta.cerebrumx.ai/ds/v1/',
  //    BB_URL:'https://staging-bb.cerebrumx.ai/v1/',
  //    CX_ML: 'https://beta.cerebrumx.ai/',
  //    CXML_URL: 'https://beta.cerebrumx.ai/',
  //    LIVE_URL: 'https://sse.cerebrumx.ai/v1/',
  //    CUSTOM_ML_URL: 'https://custom-ml.cerebrumx.ai/services/',
  //    STAGING_URL: 'https://api-staging.cerebrumx.ai/v1/dash-view/role',
  //   //  BB_URL:'https://staging-bb.cerebrumx.ai/v1/',
  //     BB_DS_URLS: 'https://beta.cerebrumx.ai/ds/v1/',
      
  //  }
  // url: {
  //  BASE_URL: 'https://api-staging.cerebrumx.ai/v1/',
  //  DS_URL: 'https://data.cerebrumx.ai/v1/',
  //  BB_DS_URLS: 'https://data.cerebrumx.ai/ds/v1/',
  //  DS_URL1: 'https://beta.cerebrumx.ai/ds/v1/',
  //   DS_URLS: 'https://data.cerebrumx.ai/',
  //   CX_ML: 'https://cxml-demo.cerebrumx.ai/',
  //   CXML_URL: 'https://cxml-staging.cerebrumx.ai/',
  //   LIVE_URL: 'https://sse.cerebrumx.ai/v1/',
  //   CUSTOM_ML_URL: 'https://custom-ml.cerebrumx.ai/services/',
  //   STAGING_URL: 'https://api-staging.cerebrumx.ai/v1/dash-view/role',
  //   BB_URL:'https://staging-bb.cerebrumx.ai/v1/'
  // }
  url: {
   BASE_URL: 'https://api-staging.cerebrumx.ai/v1/',
   DS_URL: 'https://data.cerebrumx.ai/v1/',
   DS_URL1: 'https://data.cerebrumx.ai/ds/v1/',
   BB_DS_URLS: 'https://data.cerebrumx.ai/ds/v1/',
    DS_URLS: 'https://data.cerebrumx.ai/',
    CX_ML: 'https://cxml-demo.cerebrumx.ai/',
    CXML_URL: 'https://cxml-staging.cerebrumx.ai/',
    LIVE_URL: 'https://sse.cerebrumx.ai/v1/',
    CUSTOM_ML_URL: 'https://custom-ml.cerebrumx.ai/services/',
    STAGING_URL: 'https://api-staging.cerebrumx.ai/v1/dash-view/role',
    BB_URL:'https://staging-bb.cerebrumx.ai/v1/'
  }
};